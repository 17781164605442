/* ================================================ */
/* Init */
/* ================================================ */
body {
    font-family: var(--fontFamily);
    font-weight: var(--lightFont);
    color: var(--black);
}

a {
    -moz-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
    color: var(--skyBlue);
}

a>i {
    font-style: normal;
}

.wrapper {
    border-top: none;
}

.xm-grid-header>.container {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: none;
}

.grid-container-wrapper.container.grid12-12,
.grid-column-wrapper.container.grid12-12 {
    float: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

button.button,
a.button {
    -webkit-border-fit: lines;
    overflow: visible;
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}

button.button span,
a.button span {
    text-transform: uppercase;
    border-radius: var(--borderRadius);
    font-weight: bold;
    background-color: var(--navyBlue);
    color: white;
    float: left;
    display: block;
    padding: 0;
    font-size: var(--small);
    text-align: center;
    white-space: nowrap;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
}

.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span,
a.button:hover span {
    background-color: var(--skyBlue);
    color: white;
    opacity: 1;
}

button.button span span,
a.button span span {
    font-family: var(--fontFamily);
}

.buttons-set button.button {
    margin-left: 0;
    margin-right: 5px;
    margin-bottom: 5px;
}

.button.btn-inline span,
a.button span {
    background-color: var(--navyBlue);
    color: white;
}

.button.btn-checkout span {
    background-color: var(--navyBlue);
    color: white;
}

button.add-cart-button {
    margin-right: 15px !important;
}

.grid-column-wrapper.grid12-12.no-gutter img {
    width: 100%;
}

@media only screen and (min-width: 960px) {
    .show-below-960 {
        display: none !important;
    }
}

@media only screen and (max-width: 959px) {
    .hide-below-960 {
        display: none !important;
    }
}

/* ================================================ */
/* Font and Colours */
/* ================================================ */
.main-font,
h1,
h2,
h3,
h4,
h5,
h6,
.section-title,
.products-list .product-name,
.products-grid .product-name,
h3.product-name,
.feature .heading,
.accordion .heading,
.nav-regular li.level0>a,
.nav-regular .nav-submenu--mega>li>a,
.mobnav-trigger,
.nav-mobile li.level0>a,
.nav-mobile li.level1>a,
.nav-mobile li.level2>a,
.block .block-title,
#opc-login h3,
.box-account .box-head h2,
.order-items h2.table-caption,
.order-items h2.sub-title,
.order-items .order-comments h2,
.product-view .box-reviews dt .heading,
.gen-tabs .tabs a,
.footer .heading,
#subscribe-form label,
.caption,
.heading {
    font-family: var(--fontFamily);
}

#root-wrapper a:hover span.fa:before,
.header-top a:hover,
#nav .nav-panel--dropdown a:hover,
p.required,
.product-options dt label.required em,
.form-list label.required em,
a:hover,
.vertnav li.current>a,
.header .dropdown-menu a:hover,
.header .open>.dropdown-toggle.cover>div a:hover,
.header .form-search .search-autocomplete li:hover {
    color: var(--skyBlue);
}

.main-container {
    background-color: white;
    padding: 0 !important;
}

.price-box .minimal-price .price,
.price-box .regular-price .price,
.price-box-bundle .full-product-price .price {
    color: var(--navyBlue);
}

.fa.fa-star::before {
    content: "\f004";
}

#inline-search-results .highlight {
    background-color: #ff8200;
}

/* ================================================ */
/* Header */
/* ================================================ */
header#header {
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    background-color: white;
}

header#header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

header#header.floating .notification-header-container {
    display: none;
}

.mobile-header,
.mobile-header div[class*="grid"] {
    overflow: hidden !important;
}

.mobile-header .mobile-menu-col.grid12-3 {
    width: 23% !important;
    margin: 0;
}

.mobile-header .mobile-logo-col.grid12-8 {
    width: 64.67% !important;
    margin: 0;
}

.mobile-header .mobile-logo-col a.logo {
    padding: .5em 0;
}

#root-wrapper .mobile-header span.fa,
#root-wrapper .mobile-header button.button.button-search {
    font-size: var(--xLarge);
    line-height: 36px;
    color: var(--navyBlue);
}

#root-wrapper .mobile-header .mobile-menu-col span.fa {
    padding: 0;
}

#root-wrapper .mobile-header .mobile-logo-col span.fa,
#root-wrapper .mobile-header .mobile-logo-col button.button.button-search {
    padding: 0 .5em;
}

.mobile-header .mobile-logo-col.nav-cart .empty,
.mobile-header .mobile-logo-col .nav-cart .caret,
.mobile-header .mobile-logo-col.nav-cart .cart-total {
    display: none;
}

.mobile-header .mobile-logo-col.nav-cart .dropdown-toggle.cover>div.feature-icon-hover {
    padding: 0;
}

.header .userName,
.header .userSwitch a,
.header .welcome-msg,
.header .userCode {
    font-weight: 400;
    font-size: var(--normal);
    color: var(--navyBlue) !important;
}

.header .userCode {
    margin-right: 0;
}

.header .userSwitch {
    margin-left: 10px !important;
}

.header-container {
    position: relative;
}

.header-primary {
    padding: 0;
    position: relative;
}

.header-top,
.header .dropdown {
    line-height: 2.1em;
}

.header-primary-container {
    border-bottom: #e0e0e0 solid 1px;
}

header#header .top-links>.links>li>a,
header#header .links-container>.links>.company>dl.company-links dt a {
    color: #aaa !important;
    border: none;
    background-image: none;
}

.user-menu {
    margin: 0;
}

div.feature-icon-hover {
    font-size: var(--normal);
}

#mini-cart .feature-icon-hover a {
    color: var(--navyBlue);
}

#root-wrapper #mini-cart .feature-icon-hover a span.fa {
    font-size: var(--xLarge);
}

.links>li>a {
    padding: 0 5px;
    color: var(--navyBlue);
}

.dropdown-toggle * {
    display: inline-block;
}

.userSwitch a,
.link-logout,
.link-admin,
.link-account,
#header .link-wishlist {
    text-indent: -9999px;
    width: var(--large);
    display: inline-block;
}

.userSwitch a::after,
.link-logout::after,
.link-admin::after,
.link-account::after,
#header .link-wishlist::after {
    font-family: fontAwesome;
    font-size: var(--xLarge);
    text-indent: 0;
    float: left;
    width: 23px;
}

.userSwitch a {
    display: block;
}

.userSwitch a::after {
    content: "\f2c3";
}

.link-logout::after {
    content: "\f08b";
}

.link-admin::after {
    content: "\f013";
}

.link-account::after {
    content: "\f007";
}

.link-login,
.link-register {
    padding: 0;
    width: 100px;
    text-align: center;
    border-radius: var(--borderRadius);
    border: 2px solid var(--magenta);
    font-weight: bold;
    font-size: var(--medium);
    line-height: var(--borderRadius) !important;
}

.link-login,
.link-login:hover {
    background-color: var(--magenta);
    color: white !important;
}

.link-register,
.link-register:hover {
    margin-right: 10px;
    color: var(--magenta) !important;
}

body:not(.isPublic) .link-login::after {
    content: "\f090";
}

body:not(.isPublic) .link-register::after {
    content: "\f234";
}

#header .link-wishlist::after {
    content: "\f004";
}

div#mini-cart .hide-below-960 span.cart-total {
    text-indent: 0;
    display: block;
    float: left;
    background-color: var(--magenta);
    color: white;
    border-radius: 50%;
    width: var(--xLarge);
    height: var(--xLarge);
    text-align: center;
    line-height: var(--xLarge);
    font-weight: var(--mediumFont);
}

div#mini-cart .hide-below-960 {
    text-indent: -9999px;
    width: var(--xLarge);
}

.desktop-header-top .grid-container {
    position: relative;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
    font-weight: var(--mediumFont);
}

.module-mmenu.mmenu-wrapper[style="display: none;"] {
    display: none !important;
}

.notification-header-container {
    font-size: var(--medium);
}

.header-multi-column {
    text-align: center;
}

@media only screen and (max-width: 1279px) {
    .notification-header-container {
        font-size: var(--normal);
    }

    .phone-column {
        font-size: var(--small) !important;
    }

    .link-login,
    .link-register {
        width: 80px;
        font-size: var(--normal);
    }
}

@media only screen and (min-width: 960px) {
    .main-container {
        padding-top: 160px !important;
    }

    .page-type-home .main-container {
        padding-top: 1var(--borderRadius) !important;
    }

    header#header.floating+.main-container {
        padding-top: 99px !important;
    }
}

@media only screen and (max-width: 959px) {
    .main-container {
        padding-top: 42px !important;
    }
}

/* ================================================ */
/* Menu */
/* ================================================ */

.nav-item--home {
    display: none;
}

.nav.has-outline>.grid-full {
    margin-top: 0;
    margin-bottom: 0;
}

#nav {
    z-index: 1000;
    position: relative;
}

.nav-item.level0 {
    font-size: var(--medium);
}

.mm-panels .sidebar.sidebar-filter.hide-below-960 {
    display: block !important;
}

#nav,
.mobnav-trigger {
    border-bottom: none;
}

.mobnav-trigger,
.mobnav-trigger.active,
.mobnav-trigger:hover {
    background-color: transparent;
}

.nav-regular {
    background-color: transparent;
}

#nav.nav-mobile ul.level0 .nav-item.current>a,
#nav.nav-mobile ul.level0 .nav-item>a:hover,
.cms-index-index .nav-regular .nav-item--home>a,
.nav-mobile .nav-item.level0.current>a,
.nav-mobile .nav-item.level0>a:hover,
.nav-regular .nav-item.level0.active>a,
.nav-regular .nav-item.level0:hover>a {
    background-color: transparent;
}

.nav-container a,
.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a,
.mobnav-trigger a {
    color: var(--navyBlue);
}

.nav-regular .nav-item.level0:hover>a,
.nav-mobile .nav-item.level0>a:hover {
    color: var(--navyBlue) !important;
}

.nav-item.active>a,
.module-sub-categories-title.active {
    color: var(--navyBlue) !important;
}

.nav-item.active>a .caret {
    border-top-color: var(--navyBlue) !important;
}

.main-font,
h1,
h2,
h3,
h4,
h5,
h6,
.section-title,
.products-list .product-name,
.products-grid .product-name,
h3.product-name,
.feature .heading,
.accordion .heading,
.nav-regular li.level0>a,
.nav-regular .nav-submenu--mega>li>a,
.mobnav-trigger,
.nav-mobile li.level0>a,
.nav-mobile li.level1>a,
.nav-mobile li.level2>a,
.block .block-title,
#opc-login h3,
.box-account .box-head h2,
.order-items h2.table-caption,
.order-items h2.sub-title,
.order-items .order-comments h2,
.product-view .box-reviews dt .heading,
.gen-tabs .tabs a,
.footer .heading,
#subscribe-form label,
.caption,
.heading {
    font-family: var(--fontFamily);
}

h3 {
    font-size: var(--medium);
}

.products-grid .product-name,
.products-list .product-name,
h3.product-name {
    font-size: 1.4em;
    font-weight: 400;
}

.block .block-title {
    border-bottom: none;
    display: none;
}

.sorter .amount {
    color: #aaa;
}

.module-category-misc div:first-child {
    margin-left: 0px;
}

ul#nav.nav-regular {
    display: flex;
    justify-content: center;
}

.nav-regular li.level0>a {
    line-height: 32px;
    margin-bottom: 5px;
}

.nav-regular li.level0>a>span {
    display: inline-block;
    white-space: nowrap;
}

#nav .nav-item.level0.parent>a .caret {
    border-top-color: var(--navyBlue);
}

.nav-regular .mega>.nav-panel--dropdown,
.nav-regular li.level0>.nav-panel--dropdown {
    border-top: none;
}

.nav-regular .mega>.nav-panel--dropdown {
    width: 98vw !important;
    margin-left: -0.5%;
    /*LM commented it to make the nav bar full width
    left: 0 !important*/
}

.nav-regular li.level0>.nav-submenu.nav-panel--dropdown {
    width: 33.33% !important;
}

.nav-panel--dropdown.nav-panel.tmp-full-width .nav-item>.module-sub-categories-title span {
    font-weight: bold;
}

.module-sub-categories ul:not(:first-child) li {
    padding: 0 0 5px 15px !important;
}

.nav-panel--dropdown.nav-panel.tmp-full-width .nav-item>ul>li>a.module-sub-categories-title {
    display: block;
    padding-bottom: 5px;
}

.nav-panel--dropdown.nav-panel.tmp-full-width .nav-item ul ul {
    background-color: #f5f5f5;
}

.nav-panel--dropdown.nav-panel.tmp-full-width .nav-item ul ul li:first-of-type {
    padding-top: 5px;
    margin-top: 5px;
}

.nav-panel--dropdown.nav-panel.tmp-full-width .nav-item ul ul li a.module-sub-categories-title span {
    font-size: 13px;
    font-weight: bold;
}

.nav-mobile.opt-sb2 li.level0>div>div>.nav-block {
    padding: 0 10px;
}

.nav-mobile .opener:before {
    content: "\f107" !important;
    padding: 10px 10px 10px var(--xLarge) !important;
}

.nav-mobile .opener.active:before,
.nav-mobile li.active>.opener:before {
    content: "\f106" !important;
}

.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}

.form-search .button i {
    font-style: normal;
    font-size: var(--xLarge);
}

.module-search {
    position: unset !important;
}

.module-search .form-search .button-search .fa {
    font-size: var(--xLarge);
}

#mini-cart .feature-icon-hover a .breadcrumbs .nav-filter {
    float: right;
    font-size: var(--medium);
    font-weight: 400;
}

#breadcrumbs {
    margin: var(--xLarge);
}

#breadcrumbs a {
    text-transform: uppercase;
}

.details-main-container .breadcrumbs {
    margin-bottom: 10px;
    margin-left: 10px;
}

#filter_list_box dt,
#mm-filter_list_box dt {
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 5px;
    margin-bottom: 15px;
}

#filter_list_box dd .fa {
    margin-right: 6px;
}

#filter_list_box dd a {
    margin-bottom: 10px;
}

.std i {
    font-style: normal;
    font-size: var(--large);
    margin-bottom: 5px;
    margin-right: 6px;
}

.category-products .toolbar {
    border: none;
    width: 100%;
    text-align: right;
    background-color: var(--navyBlue);
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
}

.category-products .toolbar .sorter {
    display: inline-block;
}

.category-products-grid.hover-effect .item:hover,
.products-list.hover-effect .item:hover {
    box-shadow: none;
    z-index: 0;
}

.category-products .item {
    box-shadow: 3px 5px 10px rgb(0 0 0 / 15%);
}

.sidebar.in-sidebar.sidebar-category,
.sidebar.in-sidebar.my-account-sidebar {
    background-color: #f5f5f5;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
}

.sidebar-category .accordion li:not(:last-of-type),
.my-account-sidebar ul li:not(:last-of-type) {
    border-bottom: 1px solid #ccc;
}

.sidebar-category .accordion li a,
.my-account-sidebar ul li a {
    color: var(--navyBlue);
}

.sidebar-category .accordion li a:hover,
.my-account-sidebar ul li a:hover {
    color: var(--skyBlue);
}

.sidebar.in-sidebar.sidebar-category .accordion>li>a {
    font-weight: bold;
}

.sidebar a {
    font-size: var(--normal);
}

.mini-products-list li {
    list-style: none;
}

.breadcrumbs li {
    display: inline-block;
    vertical-align: top;
}

.product-shop h1 {
    font-size: var(--borderRadius);
    text-transform: initial;
}

#inline-search-results {
    left: unset;
    right: 0;
}

body.isPublic ul#nav.nav-regular {
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
}

@media only screen and (max-width: 959px) {

    .form-list .field,
    .form-list input.input-text,
    .form-list select,
    .progress,
    div[class^=strength_meter_passwd] {
        width: 100%;
    }

    .nav-item.active>a,
    .module-sub-categories-title.active {
        color: #000000 !important;
        background-color: #f3f3f3 !important;
    }

    .nav-item.active>.opener {
        color: white !important;
    }

    .xm-grid-product .grid-left,
    .xm-grid-product .grid-right {
        width: 100% !important;
    }

    .product-shop h1,
    .product-shop .product-type-data {
        text-align: center !important;
    }

    .grid-col2-sidebar,
    .product-search-container .sidebar-category,
    .product-search-container .has-sidebar.grid-col2-main {
        width: 100%;
    }

    .details-main-container .breadcrumbs {
        display: none;
    }

    .sorter .sort-by {
        display: none;
    }

    .links>li>a {
        padding: 0 10px;
    }

    .item-welcome-msg {
        display: block !important;
    }

    .product-shop {
        margin-top: var(--xLarge);
    }

    .grid-full,
    .grid12-12 {
        overflow: auto;
    }

    .header-primary-container {
        display: none;
    }

    .nav-item .header-top-container {
        background-color: white;
    }

    .search-wrapper-mobile {
        width: 36px !important;
        height: 36px;
        margin: 0 !important;
        padding: 0;
        float: none;
        vertical-align: top;
    }

    .search-wrapper-mobile .form-search input {
        display: none;
    }

    .mobnav-trigger-wrapper {
        display: inline-block !important;
        vertical-align: top;
        float: none !important;
        padding: 10px .5em !important;
    }

    .mobnav-trigger {
        padding: 0;
    }

    .mobnav-trigger>span:last-child {
        display: none;
    }

    .mobnav-trigger .trigger-icon {
        padding-top: 0;
        margin: 0;
    }

    .mobnav-trigger .trigger-icon .line {
        background-color: #5a5a5a;
    }

    .search-wrapper-centered {
        float: none !important;
        display: inline-block !important;
    }

    #mini-cart .feature-icon-hover .hide-below-960,
    #mini-cart .feature-icon-hover .empty,
    nav .feature-icon-hover .hide-below-960,
    nav .feature-icon-hover .empty {
        display: none;
    }

    nav .feature-icon-hover {
        padding: 0 !important;
    }

    nav .feature-icon-hover .caret {
        display: none;
    }

    nav.nav {
        text-align: right;
        height: 45px;
    }

    nav.nav .header-top {
        margin: 0;
        padding: 0;
        width: 100%;
        display: block !important;
    }

    #nav.nav-mobile.show {
        display: none !important;
    }

    .nav-mobile {
        text-align: left;
    }

    .nav-logo,
    .nav-cart,
    .nav-search {
        display: inline-block;
        vertical-align: top;
    }

    .nav-logo {
        float: left;
        width: 200px;
        margin-top: 5px;
        margin-left: 8px;
    }

    .nav-cart {
        height: 36px;
    }

    .nav-cart .fa-shopping-cart {
        font-size: 1.5em !important;
        font-style: normal;
    }

    .nav-cart .hide-below-960 {
        visibility: hidden;
        position: relative;
        width: 0;
        height: 0;
        display: block !important;
    }

    .nav-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        background-color: var(--magenta);
        border-radius: 50%;
        color: white;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
    }

    .nav-cart .product-details a {
        color: #888;
    }

    .nav-cart .btn-remove,
    .nav-cart .btn-edit {
        float: right;
    }

    .nav-search .input-text {
        display: none;
    }

    .nav-search .button {
        height: 36px;
    }

    .cart-mobile {
        display: inline-block !important;
        vertical-align: top;
    }

    .cart-action-buttons {
        text-align: right;
    }

    .nav-item .header-top {
        display: block !important;
    }

    .header-top .item {
        display: block;
    }

    .nav-mobile .nav-block {
        display: initial;
    }

    .nav-mobile .nav-block,
    .nav-mobile .nav-block>.grid12-12 {
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
    }

    .nav-mobile .nav-block>.grid12-12 {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .module-sub-categories .nav-item {
        width: 100% !important;
    }

    .module-sub-categories .nav-item>a {
        padding: 15px 54px 15px var(--xLarge) !important;
        text-transform: uppercase !important;
        font-size: var(--normal) !important;
        font-weight: 400 !important;
    }

    .module-sub-categories .nav-item .page-item {
        padding: 0 !important;
    }

    .module-sub-categories .nav-item .page-item>a {
        padding: 15px 10px 15px 40px !important;
        display: block;
        text-transform: uppercase;
    }

    .acco .module-sub-categories div.nav-item ul li {
        padding-left: 0 !important;
    }

    .nav-mobile .nav-panel-inner .opener {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .nav-mobile li.nav-item {
        font-size: var(--normal);
        background-color: #f7f7f7;
    }

    .nav-mobile .nav-item.parent a {
        background-color: transparent;
        border-bottom: 1px solid #e5e5e5;
        font-size: var(--normal);
        font-weight: 400;
    }

    .mm-menu {
        position: fixed !important;
    }

    .mm-navbar_sticky {
        display: none;
    }

    .mm-menu_offcanvas {
        z-index: 0 !important;
        width: 80% !important;
    }

    .mm-menu_opened {
        display: block !important;
    }

    .header-container .col-main {
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .nav-item.show-below-960 {
        padding: 10px !important;
    }

    .nav-item.show-below-960>div {
        display: block !important;
        padding: 0.3em 1em;
    }

    .nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }

    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }

    .nav-search.fly-out .form-search .button {
        color: #aaa;
        top: 10px;
        right: 10px;
    }

    .nav-search.fly-out .form-search .button-search {
        right: 45px;
    }

    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }

    .nav-search.fly-out .form-search .button-close {
        display: block;
    }

    .block.mm-panel.mm-panel_opened {
        text-align: left;
        padding-left: 25px;
        overflow-y: scroll;
    }

    .sidebar-category .block .block-title,
    .sidebar-filter .block .block-title,
    .sidebar-category .block .block-content ul.accordion li a,
    .sidebar-filter .block .block-content ul.accordion li a {
        font-size: var(--normal);
    }

    .sidebar-category .block .block-title,
    .sidebar-filter .block .block-title {
        padding-left: 5px;
        background-color: var(--navyBlue);
        color: white;
        position: relative;
        display: block;
    }

    .sidebar-category .block .block-title::after,
    .sidebar-filter .block .block-title::after {
        content: "\f067";
        font-family: 'FontAwesome';
        position: absolute;
        right: 15px;
        font-size: var(--medium);
    }

    .sidebar-category .block .block-title.open::after,
    .sidebar-filter .block .block-title.open::after {
        content: "\f068";
    }

    .sidebar-category .block .block-title+.block-content,
    .sidebar-filter .block .block-title+.block-content {
        display: none;
    }

    .sidebar-category .block .block-title.open+.block-content,
    .sidebar-filter .block .block-title.open+.block-content {
        display: block;
    }
}

@media (max-width: 600px) {
    #my-menu {
        display: none !important;
    }
}

@media (min-width: 601px) {
    #mm-my-menu {
        display: none !important;
    }
}

/* ================================================ */
/* Content: Logo and Search  */
/* ================================================ */
.header .logo-wrapper {
    text-align: center;
    margin: 10px 0;
}

.header .logo-wrapper a.logo {
    display: block;
}

.header .logo {
    max-width: 200px;
}

.search-wrapper-centered {
    float: right;
    margin-top: var(--large);
    margin-bottom: 7px;
    display: none;
    width: 25%;
}

.search-wrapper-centered .form-search {
    margin: 0 auto;
}

.search-wrapper-centered .form-search label {
    display: none;
}


.form-search .button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.375em;
    color: #353535;
    padding: .5em;
}

@media only screen and (min-width: 960px) {
    .user-link-wrapper {
        float: right;
    }

    .logo-wrapper {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .search-wrapper .form-search .input-text {
        width: 36px;
        padding-right: 36px !important;
        background-color: transparent;
        position: relative;
        float: right;
    }

    /* .search-wrapper .form-search .input-text:hover, .search-wrapper .form-search .input-text:focus {
        width: 200px !important;
        position: absolute !important;
        right: 0;
        top: 11px;
        background-color: hsla(0,0%,88.2%,.8) !important;
        transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -webkit-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
        font-weight: bold;
        border: none !important;
    }
    .search-wrapper .form-search .input-text::placeholder, .search-wrapper .form-search .input-text:hover::placeholder, .search-wrapper .form-search .input-text:focus::placeholder {
        color: #888;
    } */
    .form-search:hover {
        position: unset;
    }
}

@media only screen and (min-width: 768px) {
    .logo-wrapper>a {
        margin: 0 auto !important;
    }
}

/* ================================================ */
/* Content: Home Page  */
/* ================================================ */
.main-categories h2 {
    margin: 0;
}

/* ================================================ */
/* Content: Product Listing  */
/* ================================================ */
#mm-nav-filter {
    display: block;
    width: 80vw;
    text-align: left;
    margin-left: 5vw;
}

.products-list .item button.button {
    display: block !important;
    width: 100% !important;
    max-width: 180px;
}

.products-list .item button.button.more-info {
    margin-top: 10px;
}

.products-list .item button.button span span {
    padding: 0;
}

.sorter .view-mode span.line {
    color: transparent;
}

#listing-ul .item .display-onhover {
    display: block !important;
}

ul#listing-ul>.item.variant .price::before {
    content: "from ";
    font-size: var(--normal);
}

ul#listing-ul>.item.variant button.button.more-info span span {
    text-indent: -9999px;
    position: relative;
}

ul#listing-ul>.item.variant button.button.more-info span span::after {
    text-indent: 0;
    content: "Select Size";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@media only screen and (max-width: 1480px) {
    .products-grid.category-products-grid.itemgrid button.button {
        display: block;
        margin: 0 auto !important;
        width: 140px;
    }

    .products-grid.category-products-grid.itemgrid button.button.more-info {
        margin-top: 10px !important;
    }

    .products-grid.category-products-grid.itemgrid button.button span {
        width: 100%;
    }

    .products-grid.category-products-grid.itemgrid button.button span span {
        padding: 0;
    }
}

@media only screen and (min-width: 1481px) {
    ul#listing-ul>.item.variant button.button.more-info span span {
        width: 70px;
    }
}

@media only screen and (max-width: 959px) {
    .col-main.grid12-12.in-col2.has-sidebar.grid-col2-main {
        width: 98% !important;
    }
}

/* ================================================ */
/* Content: Product Detail  */
/* ================================================ */
.product-shop .price-box .regular-price .price {
    font-size: 2em;
}

.matrix-table .price-box .regular-price .price {
    font-size: 1em;
}

.product-shop .btn-cart span span {
    padding: 5px 40px;
    font-size: 1.1666em;
}

.product-shop .matrix-table .z4 {
    background-color: var(--magenta) !important;
    color: white;
}

.product-shop .z4 .price-box .minimal-price .price,
.product-shop .z4 .price-box .regular-price .price,
.product-shop .z4 .price-box-bundle .full-product-price .price,
.product-shop .z4 .vstock {
    color: white
}

.img-box-style1 .product-image {
    border: none;
    padding: 0;
}

.product-shop h1 {
    color: var(--black);
}

.product-shop ul {
    list-style: disc inside;
}

.product-shop ul.add-to-links {
    list-style: none;
}

.product-shop .product-options dd {
    padding-left: 0;
}

.price-box .regular-price .price,
.price-box .minimal-price .price,
.price-box-bundle .full-product-price .price {
    color: var(--navyBlue);
    letter-spacing: 0.5px;
    font-family: var(--fontFamily);
    font-size: var(--large);
    font-weight: bold;
}

.product-options dt label,
.product-shop .qty-wrapper label {
    font-size: 1.3em;
}

.product-shop .qty-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
}

.product-shop #unit-messure-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 15px;
}

.product-shop .qty-wrapper input {
    font-size: 1.2em;
    min-width: 150px;
}

.product-shop #add-to-cart-block {
    margin-right: 15px;
}

.product-shop button.add-cart-button {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0;
}

.product-view .product-shop button.add-cart-button {
    min-width: 140px;
}

.product-shop button.add-cart-button span {
    width: 100%;
    padding: 0;
    color: white;
    background-color: var(--navyBlue);
}

.product-shop button.add-cart-button:hover span,
.product-shop button.add-cart-button span:hover {
    background-color: var(--skyBlue) !important;
    color: white;
}

.product-shop .short-description {
    font-size: var(--medium);
    line-height: 1.25;
    font-weight: 100;
}

.product-shop ul li {
    font-size: 1em;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop p {
    font-size: var(--medium);
    line-height: 1.75;
    font-weight: 100;
}

.product-shop .product-type-data {
    font-size: 13px;
    margin-top: 11px;
}

.variations .swatches-con.list,
.variations .swatchesCon.list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--small);
}

.variations .swatches-con.list .swatch,
.variations .swatchesCon.list .swatch {
    display: inline-flex !important;
}

.variations .swatches-con.list .swatch .text,
.variations .swatchesCon.list .swatch .text {
    height: 46px !important;
    line-height: 46px !important;
    width: 100%;
    border-radius: 4px;
    font-size: 1.3em !important;
    overflow: hidden;
}

.variations .swatches-con.list .swatch.selected .text {
    border: 1px solid #000 !important;
    background-color: #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    color: white !important;
    font-size: 1.3em !important;
}

.variations .swatches-con.list .swatch.available:hover .text {
    border: 1px solid #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    font-size: 1.3em !important;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 36px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 36px !important;
}

div#product-tabs .panel:first-of-type {
    font-size: var(--medium);
    line-height: 1.25;
}

form[data-product-type="Products_variant_model"] .price-box .price[content]::before {
    content: "from";
    font-size: var(--medium);
}

.productTabContent a {
    color: #ff8200;
    font-weight: bold;
}

.product-view input#item-quantity::before {
    content: "Qty";
}

#cart_product_comment {
    display: none !important;
}

@media only screen and (max-width: 767px) {
    .product-image.zoom-inside a#zoom1 {
        pointer-events: none;
    }
}

/* ================================================ */
/* Content: View Cart  */
/* ================================================ */
#quotebtn,
#submit_promo+button.button.discount_button {
    display: none;
}

table#shopping-cart-totals-table {
    font-size: var(--small);
}

/* ================================================ */
/* Content: Checkout  */
/* ================================================ */
.opc .step-title,
.opc .allow .step-title {
    background-color: var(--navyBlue) !important;
}

.opc .step-title {
    opacity: 0.3;
}

.opc .allow .step-title {
    opacity: 1;
}

.opc .active .step-title {
    background-color: var(--skyBlue) !important;
}

.sidebar.in-sidebar.opc-progress-container {
    background-color: #f5f5f5;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
}

@media only screen and (min-width: 768px) {
    .sidebar.in-sidebar.opc-progress-container {
        position: fixed;
    }
}

/* ================================================ */
/* Content: Others  */
/* ================================================ */
.promo-header {
    font-family: var(--fontFamily);
    color: white;
    background-color: var(--navyBlue);
    padding-top: 11px;
    font-size: 1em;
    letter-spacing: 0.5px;
}

.homepage-carousel-caption {
    margin: 0;
    position: absolute;
    bottom: 7%;
    left: 7%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;
    font-weight: 400;
    text-transform: none;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    color: white;
}

.homepage-carousel-caption h2 {
    font-size: 3em;
    font-weight: 400;
    text-align: left;
    margin: 0;
    line-height: 1.5em;
    text-transform: uppercase;
}

.homepage-button {
    padding: var(--xLarge);
    font-family: var(--fontFamily);
    float: left;
    background-color: var(--navyBlue);
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border: none;
    border-radius: var(--borderRadius);
    -webkit-transition: background-color 0.15s ease-out;
    -moz-transition: background-color 0.15s ease-out;
    -o-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;
}

.homepage-button:hover {
    background-color: var(--black);
    color: white;
}

.fa-chevron-right:before {
    content: "\f054";
    display: inline-block;
    font: normal normal normal var(--normal)/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

.slider-arrows2 .owl-controls div {
    background-color: transparent;
}

.page-content h2 {
    font-size: 2em;
}

/*Newsletter Module Home page*/
#subscribe-form form {
    display: block;
    text-align: center;
    text-transform: capitalize;
}

#subscribe-form {
    margin-top: var(--borderRadius);
    padding: 1% 1% var(--borderRadius) 0;
}

.section-title.padding-right {
    padding-right: 0;
    font-size: 2em;
    text-align: center;
}

#subscribe-form label.newsletter-label-input_email {
    display: block;
    color: var(--navyBlue);
    text-align: left;
    letter-spacing: 1.2px;
    font-size: var(--medium);
    font-weight: 500;
}

.newsletter-wrapper {
    width: 100%;
    margin-bottom: var(--xLarge);
}

.newsletter .section-title {
    border-bottom: none;
    background-color: #5c5c5c;
    text-align: center;
    font-size: 1.5em;
    color: white;
    letter-spacing: 0.5px;
}

.newsletter-wrapper .text_title {
    clear: both;
    display: block;
    padding: 10px;
    color: white;
}

.newsletter .inputs {
    display: block;
    width: 50%;
    margin: 0 auto;
}

#subscribe-form .input-text,
#subscribe-form .button {
    font-family: var(--fontFamily);
}

.input-box.newsletter-field-input_email {
    width: 78%;
}

.input-text.required-entry.validate-input_email {
    width: 100% !important;
    border-radius: 5px;
}

.button.btn-inline.newsletter-button {
    width: 50%;
    margin-top: 5px;
    border-radius: var(--borderRadius);
}

.button.btn-inline.newsletter-button span {
    padding: 0;
    width: 100%;
}

.button.button.btn-inline.newsletter-button span span:hover {
    background-color: var(--skyBlue);
    color: white;
    border-radius: var(--borderRadius);
}

.newsletter button.button span {
    width: 100%;
    text-transform: uppercase;
    border-radius: var(--borderRadius);
}

.block-account .block-title {
    background: none;
}

.product-options.border-out {
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
}

.product-options.border-out:after {
    content: 'Please select the options';
    color: red;
    font-size: 1.2em;
}

.add-cart-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-color: #111;
    color: white;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}

.add-cart-fixed span {
    height: 45px;
    line-height: 45px;
    font-size: 1.1666em;
    text-transform: uppercase;
}

.form-list .field {
    position: relative;
}

.itemslider-wrapper {
    background-color: #f5f5f5;
}

.itemslider-wrapper .itemslider.products-grid .owl-item .item {
    background-color: white;
    box-shadow: 3px 5px 10px rgb(0 0 0 / 15%);
    padding: 10px 0;
    margin: 10px;
}

/* ================================================ */
/* Footer */
/* ================================================ */
/*Footer*/
ul.bullet li {
    background: none;
    padding-left: 0px;
    text-transform: capitalize;
    font-family: var(--fontFamily);
    letter-spacing: 0.5px;
}

#footer {
    margin-top: 20px;
}

#footer ul li a {
    text-decoration: none;
    font-size: 1em;
    font-weight: 400;
}

h6.block-title.heading {
    color: #1d262b;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer-top-container {
    background-color: var(--navyBlue);
    padding-top: var(--borderRadius);

}

.footer-top-container img {
    opacity: 1;
    -webkit-transition: opacity 0.15s ease-out;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.footer-top-container img.main-partner-logo {
    margin: 5px 0;
    opacity: 0.5;
}

.footer-top-container img.main-partner-logo:hover {
    opacity: 0.2;
}

.footer-top-container img:hover {
    opacity: 0.5;
}

.footer-bottom-container {
    background-color: transparent;
}

.footer-bottom-container .section.clearer {
    padding: 0;
}

.footer-copyright {
    color: white;
    text-transform: capitalize;
    font-family: var(--fontFamily);
    letter-spacing: 0.5px;
    font-size: 1em;
}

.footer-links-column.headings-only .grid12-12 {
    width: auto;
    width: initial;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
    margin-left: auto;
    margin-right: auto;
    float: none;
    display: block;
}

.footer .footer-titles-only h6.block-title.heading {
    padding-bottom: var(--borderRadius);
    border-bottom: 1px solid #999;
    margin-bottom: var(--borderRadius);
}

.footer .footer-titles-only h6.block-title.heading a {
    color: var(--navyBlue);
    font-size: var(--medium);
}

.footer h6.block-title.heading {
    letter-spacing: normal;
    font-size: var(--medium);
    height: auto;
    font-weight: 500;
    letter-spacing: 1.2px;
    margin-bottom: var(--xLarge);
    padding: 0;
    color: white;
    text-transform: uppercase;
}

.footer h6.block-title.heading+div.block-content ul.bullet li a {
    font-weight: 300;
    font-size: var(--normal) !important;
}

/*Footer Copyright*/
.footer-bottom-container {
    clear: both;
}

.copyright1 {
    background: transparent;
    text-transform: capitalize;
    font-family: var(--fontFamily);
    letter-spacing: 0.5px;
    font-size: 1em;
    color: #000;
}

.footer-container {
    background-color: var(--skyBlue);
    color: white;
}

.footer a {
    font-size: var(--normal);
    color: white;
}

.footer a .fa {
    font-size: var(--xLarge);
    margin-right: 10px;
}

.footer .form-list input.input-text {
    border-radius: var(--borderRadius);
    background-color: transparent;
    border-color: white;
    color: white;
    width: 100%;
}

.footer .module_custom_form .form-list li {
    width: 48%;
    margin-right: 2%;
    float: left;
}

.footer .module_custom_form .form-list li:last-of-type,
.footer .module_custom_form ._buttons-set {
    clear: left;
}

@media only screen and (max-width: 767px) {
    .footer.container div[id*="column"][class*="grid12"] {
        margin-bottom: var(--borderRadius);
        text-align: center;
    }

    .footer .module_custom_form .form-list li {
        float: none;
        width: 100%;
        margin-right: 0;
    }
}

/* ====== VARIABLES ====== */
body {
    --navyBlue: #00123d;
    --skyBlue: #05a3fc;
    --babyBlue: #69c8fd;
    --magenta: #e20574;
    --black: #333;
    --grey: #ddd;
    --lightGrey: #eee;

    --borderRadius: 30px;

    --small: 12px;
    --normal: 14px;
    --medium: 16px;
    --large: 18px;
    --xLarge: 20px;

    --fontFamily: 'Montserrat', 'sans-serif';

    --lightFont: 300;
    --mediumFont: 500;
}