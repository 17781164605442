/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
body #root-wrapper header#header .xm-grid-header > .container {
  /* ====== NOTIFICATION HEADER CONTAINER ====== */
  /* ====== TOP HEADER CONTAINER ====== */
  /* ====== NAV HEADER CONTAINER ====== */
  /* ====== MOBILE HEADER CONTAINER ====== */
}
body #root-wrapper header#header .xm-grid-header > .container .notification-header-container p, body #root-wrapper header#header .xm-grid-header > .container .notification-header-container span {
  line-height: 30px;
  margin: 0;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top {
  color: var(--navyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container {
  display: flex;
  justify-content: space-between;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .grid-column-wrapper:not(.public) {
  width: auto;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container span.fa,
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container i.fa {
  font-size: var(--xLarge);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .header-multi-column {
  min-width: 350px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .header-multi-column .module-switch-customer span,
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .header-multi-column .module-switch-customer small {
  line-height: 58px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .header-multi-column .module-switch-customer .userName {
  max-width: 220px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .header-multi-column .links li a {
  line-height: 58px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .header-multi-column .links li a.link-register {
  font-size: var(--medium);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .header-multi-column .links li a.link-login {
  background-color: var(--skyBlue);
  color: white;
  text-shadow: 1px 1px 5px var(--navyBlue);
  font-weight: bold;
  border-radius: 30px;
  line-height: 40px;
  margin-left: 10px;
  margin-top: 9px;
  padding: 0 var(--xLarge);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .logo-column {
  width: 307px !important;
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .logo-column {
    width: 192px !important;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .logo-column .module-logo.logo-wrapper a.logo {
  height: 70px;
  max-width: none;
  position: absolute;
  top: var(--normal);
  left: 0;
  background-color: white;
  z-index: 1001;
  padding: 0 10px;
}
@media only screen and (min-width: 960px) and (max-width: 1379px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .logo-column .module-logo.logo-wrapper a.logo {
    height: 45px;
    top: 19px;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .logo-column .module-logo.logo-wrapper a.logo img {
  height: 100%;
}
@media only screen and (min-width: 1280px) and (max-width: 1379px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .logo-column.public .module-logo.logo-wrapper a.logo {
    height: 70px;
    top: var(--normal);
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .logo-column.public .module-logo.logo-wrapper a.logo {
    height: 45px;
    top: 19px;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .cart-column {
  flex: 0 0 202px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .cart-column.public {
  margin-left: 0;
  min-width: auto;
  flex: 0 0 111px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .cart-column #mini-cart .feature-icon-hover > a span.fa.fa-shopping-cart {
  padding: 0;
  line-height: 58px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .cart-column #mini-cart .feature-icon-hover > a .empty {
  display: none;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .cart-column #mini-cart .feature-icon-hover .caret {
  display: none;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .cart-column ul.links li a {
  line-height: 58px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column {
  position: relative;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column .module-search .form-search .input-text {
  top: 11px;
  border-radius: 30px;
  width: 130px;
}
@media only screen and (min-width: 1150px) and (max-width: 1279px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column .module-search .form-search .input-text {
    width: 200px;
  }
}
@media (min-width: 1280px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column .module-search .form-search .input-text {
    width: 300px;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column .module-search .form-search .button-search {
  height: 36px;
  width: 36px;
  top: 11px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column .module-search .form-search .button-search .fa {
  color: var(--navyBlue);
  padding: 0;
  margin-bottom: 0;
  line-height: 36px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column.public .module-search .form-search .input-text {
  width: 300px !important;
}
@media (min-width: 1280px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .search-column.public {
    width: 23%;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column {
  font-size: var(--normal);
  line-height: 58px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column p {
  margin: 0;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column i {
  width: var(--borderRadius);
  height: var(--borderRadius);
  line-height: var(--borderRadius);
  text-align: center;
  border-radius: 50%;
  background-color: var(--navyBlue);
  color: white;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column a {
  color: var(--navyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column a:hover {
  color: var(--skyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column strong {
  font-weight: bold;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column.public {
  margin-left: auto;
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-header-top .grid-container .phone-column.public {
    margin-right: 0;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container {
  max-width: none;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0 a span {
  font-weight: var(--mediumFont);
  color: white;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0 > a {
  border-bottom: 3px solid white;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0 > a span {
  color: var(--navyBlue);
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0 > a span {
    font-size: 13px;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0:hover > a {
  border-bottom: 3px solid var(--magenta);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0:hover > a span {
  color: var(--skyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.active > a {
  border-bottom: 3px solid var(--magenta);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.active > a span {
  color: var(--navyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.request-quote-nav {
  background-color: var(--skyBlue);
  border-radius: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
}
@media only screen and (min-width: 1500px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.request-quote-nav {
    right: 4.5vw;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.request-quote-nav {
    right: 3.5vw;
  }
}
@media only screen and (max-width: 1399px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.request-quote-nav {
    right: 2.5vw;
  }
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.request-quote-nav > a {
  line-height: 30px;
  text-shadow: 1px 1px 5px var(--navyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.request-quote-nav > a span {
  color: white;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0 .nav-panel--dropdown a span {
  color: var(--black);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown {
  max-height: calc(90vh - 130px);
  overflow-y: scroll;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories {
  display: flex;
  justify-content: center;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0.5%;
  padding-right: 0.5%;
  border-right: 1px solid #bbb;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item:last-of-type {
  border: none;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > a.module-sub-categories-title {
  color: var(--navyBlue);
  font-weight: bold !important;
  padding: 5px !important;
  text-align: center;
  line-height: normal;
  margin-bottom: 5px;
  border-bottom: 2px solid var(--magenta);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > a.module-sub-categories-title:hover > span {
  color: var(--skyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > a.module-sub-categories-title > span {
  color: var(--navyBlue);
  text-transform: capitalize;
  font-weight: bold;
  font-size: var(--normal);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul {
  margin-bottom: 10px;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item,
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.nav-item {
  background: none;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item a.module-sub-categories-title,
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.nav-item a.module-sub-categories-title {
  padding: 0 !important;
  line-height: var(--xLarge);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item a.module-sub-categories-title span,
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.nav-item a.module-sub-categories-title span {
  font-size: var(--normal);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item > ul,
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.nav-item > ul {
  display: none;
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item.active > a.module-sub-categories-title {
  border-bottom-color: var(--navyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item.active > a.module-sub-categories-title > span {
  color: var(--skyBlue);
}
body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent .nav-panel--dropdown {
  font-size: var(--normal);
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .xm-grid-header > .container .desktop-nav-container nav.nav-container nav.nav.container ul#nav li.nav-item.level0.nav-item--parent.parent .nav-panel--dropdown {
    font-size: var(--small);
  }
}
body #root-wrapper header#header .xm-grid-header > .container .mobile-header .mobile-logo-col .logo-wrapper a.logo {
  width: 121px;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  top: 2px;
}
body #root-wrapper header#header .xm-grid-header > .container .mobile-header .mobile-logo-col .dropdown-toggle .feature-icon-hover {
  padding: 0;
}

/*# sourceMappingURL=general.css.map */
